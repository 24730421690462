/*----------  main css ----------*/

.booknow-container {
  font-family: inherit !important;
  color: inherit;
  /*padding-top: 20px;*/
}

.booknow-container ol, ul {
  padding: 0 1rem;
}

.booknow-container hr {
  border-top: 2px solid rgba(13, 13, 13, .1);
  margin: 2rem 0;
}

.booknow-container table {
  border-collapse: collapse;
  border-spacing: 0;
}

.booknow-container caption, th, td {
  text-align: left;
  font-weight: normal;
  vertical-align: middle;
}

.booknow-container q, blockquote {
  quotes: none;
}

.booknow-container q:before, q:after, blockquote:before, blockquote:after {
  content: none;
}

.booknow-container a {
  text-decoration: none;
}

.booknow-container a img {
  border: none;
}

.booknow-container article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section, summary {
  display: block;
}

.booknow-container .svg-inline--fa {
  margin-right: 4px;
  height: 1em;
  overflow: visible;
  vertical-align: -0.125em;
}

/*----------  style ----------*/

.booknow-container h1 {
  font-size: 24px;
  font-weight: bold;
  margin: 12px 0;
  text-transform: none;

  color: #383838;
}

.booknow-container h2 {
  font-size: 20px;
  font-weight: bold;
  margin: 28px 0px 16px 0px;
  text-transform: none;
  color: #383838;
}

.booknow-container h3 {
  font-size: 18px;
  font-weight: bold;
  margin: 5px 0;
  color: #383838;
  text-align: left;
}

.booknow-container h4 {
  font-size: 16px;
  font-weight: bold;
  margin: 4px 0;
}

.bn-booknow-button {
  padding: 10px 14px;
  line-height: 0;
  height: 36px;
  font-weight: 600;
  min-width: 100px;
  font-size: 16px;
  cursor: pointer;
  background-color: #383838;
  border-radius: 25px;
  color: #FFF;
  transition: 0.3s;
  width: auto;
  border: medium none;
}

.bn-booknow-button:hover {
  background-color: #000;
  border: medium none;
}

.bn-booknow-button:disabled {
  opacity: 100%;
  background-color: #eaeaea;
  color: #383838;
  border: 1px solid #eaeaea;
}

.bn-booknow-button:disabled:hover {
  background-color: #eaeaea;
  border: 1px solid #eaeaea;
  color: #383838;
}

.bn-booknow-button, svg.bn-svg-button {
  cursor: pointer;
  font-family: inherit;
}

.bn-booknow-button.bn-secondary-button {
  color: #383838;
  background-color: #FFFFFF;
  border: 1px solid #383838;

  min-width: revert;
}

.bn-booknow-button.bn-secondary-button:hover {
  background-color: #F7F7F7;
}

/*body {*/
/*  color: #7f7f7f;*/
/*}*/

.bn-button-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 10px 10px;
  gap: 10px;
}

.bn-button-busy {
  padding: 0;
}

/*.booknow-container {*/
/*  padding: 60px 0;*/
/*}*/

.booknow-container .bn-error-message {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;

  width: 100%;
  padding: 15px;
  color: #d63301;
  border: 1px solid #d63301;
  border-radius: 7px;
}

.bn-page-header {
  position: sticky;
  top: 0;
  width: 100%;
  height: 140px;
  z-index: 2;
  background-color: #fff;
  transition: box-shadow 0.3s;
  padding: 0px;
}

.bn-page-header {}

.bn-page-header.lift {
  /*border-bottom: 1px solid #c0c0c052;*/
  /*box-shadow: rgb(236 239 241) 0 1px 5px 0;*/
  box-shadow: #d1d1d1 0px 1px 5px 0px;
}

.bn-page-header .bn-spinner {
  width: 100%;
}

.bn-page-container {
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  margin: 25px auto 25px auto;
  align-items: center;
}

/*.disable-scroll {*/
/*  height: 100%;*/
/*  overflow: hidden;*/
/*}*/

/* ---------- header -------------- */
.bn-login-header {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0px 10px 0px;
  height: 40px;
  gap: 20px;
}

.bn-login-button {
  color: #383838;
  line-height: 24px;
  text-transform: none;
  cursor: pointer;
  padding-bottom: 2px;
  font-size: 14px;
}

.bn-login-button:hover {
  line-height: 24px;
  padding-bottom: 0;
  border-bottom: 2px solid #7f7f7f;
}

.bn-login-name {
  display: flex;
  flex-direction: row;
  gap: 4px;
  cursor: pointer;
  height: 20px;
  color: #383838;
  font-weight: bold;
}

.bn-login-name:hover {
  border-bottom: 2px solid #7f7f7f;
}

.bn-password-progress {
  display: flex;
  flex-direction: row;

  padding: 8px 4px;
}

.bn-progress-item {
  background-color: #d63301;
  height: 4px;
  flex: 1 1;

  transition: background-color 0.3s linear;
}

.bn-progress-item.valid {
  background-color: #5CCC5C;
}

.bn-restriction-item {
  display: flex;
  padding: 4px 0;

  color: #d63301;
}

.bn-restriction-item svg {
  width: 0;

  transition: width 0.3s linear;
}

.bn-restriction-item.valid {
  color: #5CCC5C;
}

.bn-restriction-item.valid svg {
  width: 25px;

  transition: width 0.3s linear;
}

.bn-forget-password-container p {
  margin-bottom: 25px;
}

.bn-forget-password-container p span {
  height: 18px;
  cursor: pointer;
  text-transform: uppercase;

  color: #7f7f7f;
}

.bn-forget-password-container p span:hover {
  border-bottom: 2px solid #7f7f7f;
}

.bn-forget-password-container .bn-success-message {
  max-width: 320px;
  margin-bottom: 25px;
}


/* --------- search results --------- */
.bn-search-results {
  display: flex;
  flex-direction: row;
  padding: 0px 25px;
  margin: 0 auto;
  width: 100%;
}

.bn-search-results-container {
  flex: 1 1;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 38px;
  width: 100%;
}

.bn-search-results-container .bn-error-message {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;

  width: 100%;
  padding: 15px;
  color: #d63301;
  border: 1px solid #d63301;
  border-radius: 7px;
}

.bn-search-spinner-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.bn-alternative-hotels-results-container {
  width: 100%;
}

.bn-alternative-hotels-results-container h2 {
  text-align: left;
}

.bn-hotel {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 358px;
  height: 380px;
  cursor: pointer;
  background-color: white;
  border-radius: 8px;
}

.bn-hotel-contracted .bn-image-slide {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.bn-contracted-container {
  height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;

  color: #FFFFFF;
  font-weight: bold;
  background-color: #383838;
  border-top: 1px solid #383838;
  border-right: 1px solid #383838;
  border-left: 1px solid #383838;

  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
}

.bn-hotel-heading {
  margin-bottom: 10px;
}

.bn-hotel-area {
  display: flex;
  flex-direction: row;
  gap: 10px;
  color: #7f7f7f;
  padding-top: 2px;
}

.bn-hotel-summary {
  display: flex;
  flex-direction: column;
  padding: 10px 0;
}

.bn-hotel-rate {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: end;

  font-size: 15px;
}

.bn-hotel-rate .bn-booknow-button {
  min-width: 74px;
  height: 30px;

  font-size: 14px;
}

.bn-hotel-rate div {
  color: #7f7f7f;
}

.bn-hotel-rate div strong {
  font-weight: bold;
  color: #383838;
}

.bn-hotel-rate strong {
  font-size: larger;
  padding-left: 5px;
}

.bn-hotel-not-available {
  color: #7f7f7f;
  padding: 8px 0 0 0;
  font-size: 85%;
}

.bn-hotel-not-available strong {
  color: #d63301;
  font-size: medium;
}

.bn-hotel-not-available a {
  color: #383838;
  border-bottom: 1px solid #383838;
}


/* --------- image slider --------- */

.bn-image-slider {
  height: 100%;
  position: relative;
}

.bn-image-slide {
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
  border-radius: 7px;
}

.bn-image-slider .bn-arrow {
  visibility: hidden;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  font-size: 45px;
  color: #fff;
  z-index: 1;
  cursor: pointer;
  opacity: 60%;
  transition: opacity 0.3s, visibility 100ms;
}

.bn-image-slider .bn-arrow:hover {
  opacity: 100%;
}

.bn-image-slider .bn-arrow.hover {
  visibility: visible;
}

.bn-image-slider .bn-arrow.bn-left-arrow {
  left: 32px;
}

.bn-image-slider .bn-arrow.bn-right-arrow {
  right: 32px;
}

.bn-dot-container {
  position: absolute;
  top: 85%;
  margin: 0 auto;
  width: 100%;

  display: flex;
  justify-content: center;
  transition: visibility 100ms;
}

.bn-dot {
  margin: 0 3px;
  cursor: pointer;
  font-size: 20px;

  color: #fff;
  opacity: 60%;
  transition: opacity 0.3s;
}

.bn-dot.bn-selected {
  color: #fff;
  opacity: 1;
}

/* --------- image gallery --------- */

.bn-image-gallery {
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 8px;
  height: 100%;
  padding: 0px;
}

.bn-image-gallery-primary-image {
  flex: 2 1;

  border-radius: 7px 0 0 7px;
}

.bn-image-gallery-secondary-images {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.bn-image-gallery-secondary-images div:nth-child(1) {
  border-radius: 0 7px 0 0;
}

.bn-image-gallery-secondary-images div:nth-child(2) {
  border-radius: 0 0 7px 0;
}

.bn-image-gallery .bn-image {
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
  transition: filter 350ms;
  transition-timing-function: ease-in-out;
}

.bn-image-gallery .bn-image:hover {
  filter: brightness(85%);
}

.bn-image-gallery-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  height: 100%;
  padding: 0 0 10px 10px;
  outline: none;

  background-color: #fff;
}

.bn-image-gallery-header {
  position: sticky;
  top: 0;

  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 10px 0 0 0;
}

.bn-image-gallery-header .bn-svg-button {
  color: #b1b1b1;
  font-size: 200%;
  padding: 0 10px;
}

.bn-image-gallery-images {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;

  width: 100%;
}

.bn-image-gallery-images .bn-image {
  width: 100%;
  height: 100%;

  max-width: 900px;
  max-height: 500px;
  background-position: center;
  background-size: cover;
}

/* --------- spinner --------- */

.bn-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
}

.bn-spinner h4 {
  margin: 5px;
}

/* https://projects.lukehaas.me/css-loaders/ , Loader #3 */
.bn-loader {
  font-size: 10px;
  /*margin: 50px auto;*/
  text-indent: -9999em;
  width: 11em;
  height: 11em;
  border-radius: 50%;
  background: #ffffff;
  background: linear-gradient(to right, #000 10%, rgba(255, 255, 255, 0) 42%);
  position: relative;
  animation: load3 1.4s infinite linear;
  transform: translateZ(0);
}

.bn-loader:before {
  width: 50%;
  height: 50%;
  background: #ffffff;
  border-radius: 100% 0 0 0;
  position: absolute;
  top: 0;
  left: 0;
  content: '';
}

.bn-loader:after {
  background: #FFFF;
  width: 75%;
  height: 75%;
  border-radius: 50%;
  content: '';
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}


/* --------- hotel detail --------- */

.bn-hotel-detail-container {
  display: flex;
  flex-direction: column;
  padding: 0px 25px;

  width: 100%;
}

.bn-hotel-detail-container .bn-error-message {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;

  color: #d63301;
  border: 1px solid #d63301;
  border-radius: 7px;
}

.bn-hotel-slide-container {
  width: 100%;
  height: 400px;
  margin: 35px 0;
}

.bn-hotel-detail-container h1 {
  margin-bottom: 5px;
}

.bn-hotel-banner {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 25px;
}

.bn-hotel-description {
  flex: 1 1;
  margin-bottom: 30px;
  padding-right: 4px;
}

.bn-hotel-description p {
  margin: 10px 0;
  line-height: 1.4em;
}

.bn-hotel-banner .bn-hotel-slide-container {
  flex: 2 1;
  margin: 0;
}

.bn-hotel-feature-container {
  color: #383838;
  margin-bottom: 30px;
}

.bn-hotel-feature-container h2 {
  margin-bottom: 5px;
}

.bn-feature-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.bn-hotel-feature {
  display: flex;
  font-size: 15px;
  padding: 10px 20px 10px 0px;
}

.bn-hotel-room-detail {
  display: flex;
  flex-direction: row;
  gap: 25px;

  justify-content: space-between;
}

.bn-hotel-rooms-container {
  flex: 1 1;

  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}

.bn-hotel-room {
  display: flex;
  flex-direction: column;

  border-bottom: 1px #C0C0C0 solid;
  padding-bottom: 20px;
}

.bn-room-detail-container {
  display: flex;
  flex-direction: row;
  gap: 25px;

  width: 100%;
  height: auto;
}

.bn-room-slide-container {
  width: 300px;
  height: 224px;
}

.bn-room-info {
  width: 400px;
  flex: 1 1;
}

.bn-hotel-bed-config {
  display: flex;
  align-items: center;
  margin: 15px 0 10px 0;

  gap: 4px;
}

.bn-hotel-bed-config svg {
  color: #383838;
  font-size: 20px;
}

.bn-hotel-bed-config p {
  padding-top: 5px;
  font-size: smaller;
}

.bn-room-description p {
  line-height: 1.4em;
}

.bn-room-rate-tax-info {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-bottom: 10px;
  font-size: small;
}

.bn-room-rates-container {
  display: flex;
  flex-direction: column;
}

.bn-rate-detail-container {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  width: 100%;
}

.bn-rate-info-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  /*margin-top: 20px;*/
  /*width: 100%;*/
}

.bn-rate-description-container {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  font-size: 16px;
}

.bn-rate-button-container {
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: center;
}

.bn-rate-total-stay-rate {
  color: #383838;
  font-weight: bold;
  font-size: 21px;
}

.bn-rate-total-stay {
  font-weight: bold;
  font-size: 12px;
  padding-bottom: 2px;
}

.bn-rate-button-container .bn-booknow-button {
  width: 120px;
}

.bn-rate-button-container .bn-button-busy {
  width: 120px;
}

.bn-button-busy .bn-loader {
  background: #fff;
  background: linear-gradient(to right, #fff 10%, rgba(255, 255, 255, 0) 42%);
}

.bn-button-busy .bn-loader::after {
  background-color: #383838;
}

.bn-rate-saving-total {
  font-weight: bolder;
  color: #d63301;
  text-transform: uppercase;
  font-size: 15px;
}

.bn-rate-original-total {
  text-decoration: line-through;
  font-size: 16px;
}

.bn-rate-mealplan {
  font-weight: bold;
  color: #383838;
  margin-bottom: 8px;
}

.bn-rate-info {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.bn-rate-info ul {
  padding-left: 14px;
}

.bn-rate-info p {
  font-size: 14px;
  line-height: 1.2rem;
  margin-right: 2px;
}

.bn-dialog-content.bn-dialog-idle-message {
  text-align: center;
}

.bn-rate-requirement {
  font-weight: bolder;
  color: #d63301;
  text-transform: uppercase;
}

.bn-rate-terms-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  font-size: 13px;
  margin: 10px 0;
}

.bn-rate-terms-container h4 {
  font-size: 13px;

  color: #383838;
}

.bn-rate-deposit-policy-container {
  flex: 1 1;
}

.bn-rate-deposit-policy-container p, .bn-rate-deposit-policy-container h4 {
  line-height: 1.5em;
}

.bn-rate-cancel-policy-container {
  flex: 1 1;
}

.bn-rate-cancel-policy-container p, .bn-rate-cancel-policy-container h4 {
  line-height: 1.5em;
}

.bn-hotel-map-container {
  display: flex;
  flex-direction: column;
}

.bn-hotel-map {
  width: 100%;
  height: 350px;

  border-radius: 7px;
  overflow: hidden;
}

/* --------- itinerary --------- */

.bn-itinerary {
  flex: 1 1;
  position: sticky;
  top: 150px;
  display: flex;
  flex-direction: column;
  padding: 20px;
  max-width: 350px;
  height: fit-content;
  gap: 12px;
  border: 1px #C0C0C0 solid;
  border-radius: 0;
  transition: width .3s ease-in-out;
  box-shadow: rgba(0, 0, 0, 0.12) 0 6px 16px;
}

.bn-itinerary-heading-container {
  display: flex;
  justify-content: space-between;
}

.bn-itinerary-heading {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.bn-itinerary-count {
  padding: 4px;
  width: 28px;
  height: 28px;

  color: #FFFFFF;
  background-color: #383838;
  font-size: 21px;
  text-align: center;
}

.bn-itinerary h2 {
  margin-top: 4px;
}

.bn-itinerary-scroll-to-total {
  display: flex;
  flex-direction: column;

  color: #383838;
  font-size: 22px;

  cursor: pointer;
}

.bn-itinerary-scroll-to-total span {
  font-size: 12px;
}

.bn-itinerary-item {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.bn-itinerary-remove-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.bn-itinerary-remove-container .bn-svg-button {
  color: #383838;
  padding: 5px;
}

.bn-itinerary-dates-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.bn-itinerary-checkin-checkout-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
}

.bn-itinerary-roomtype-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.bn-itinerary-pax-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.bn-itinerary-roombreakdown-container {
  display: flex;
  flex-direction: row;
}

.bn-itinerary-roombreakdown-container span {
  padding-right: 12px;
}

.bn-itinerary-property-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.bn-itinerary-property-container h3 {
  color: #000;
}

.bn-itinerary-amount-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.bn-itinerary-item-separator {
  border-top: 1px #C0C0C0 solid;
}

.bn-itinerary-total-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  border-top: 2px #383838 solid;
}

.bn-itinerary-continue-button button {
  width: 100%;
}

.bn-itinerary-continue-button .bn-spinner {
  height: 48px;
}

.bn-desposit-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;

  margin-top: 15px;
}

/*-----------itinerary mobile-----------*/
.bn-itinerary-mobile {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100vw;
  padding: 20px;
  /*height: 90px;*/
  z-index: 2;

  display: flex;
  flex-direction: column;

  border: 1px #C0C0C0 solid;
  background-color: #FFFFFF;
}

.bn-itinerary-mobile-detail {
  height: 0;
  overflow: hidden;

  transition: height .3s ease-in-out;
}

.bn-itinerary-mobile-summary {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.bn-itinerary-mobile-summary-expand {
  display: flex;
  flex-direction: row;
  gap: 4px;

  cursor: pointer;
  color: #383838;
}

.bn-itinerary-mobile-summary-expand svg:hover {
  color: #7f7f7f;
}

.bn-itinerary-mobile-stay {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 4px;
}

/* --------- room breakdown -------- */
#bn-breakdown-dialog .bn-dialog {
  max-width: 800px;
}

.bn-dialog-roombreakdown-container {
  display: flex;
  flex-direction: row;
  justify-content: center;

  gap: 10px;
  padding: 25px 0;
}

.bn-roombreakdown-room-container {
  display: flex;
  flex-direction: column;

  gap: 10px;
}

.bn-roombreakdown-room-container .bn-group {
  width: auto;
  margin-bottom: 0;
}

.bn-roombreakdown-room {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  gap: 10px;
  margin-bottom: 10px;
}

.roombreakdown-bed-config {
  display: flex;
  flex-direction: row;

  gap: 15px;
}

.roombreakdown-bed-config svg {
  font-size: 26px;
}

.roombreakdown-bed-config p {
  font-size: smaller;
  padding-bottom: 5px !important;
}

.bn-roombreakdown-roomNo {
  padding: 0 10px;
}

.bn-roombreakdown-total-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 5px;
  margin-bottom: 25px;

  border-top: 2px solid #383838;
}

#bn-breakdown-dialog p {
  padding-bottom: 25px;
}

#bn-breakdown-dialog .bn-error-message {
  display: flex;
  flex-direction: row;
  gap: 5px;

  padding: 15px;
  margin-bottom: 25px;
  border-radius: 7px;

  color: #D63301;
  background-color: #FFBABA;
}

/* --------- itinerary date picker dialog -------- */
#bn-itinerary-datepicker-dialog .bn-dialog {
  max-width: 800px;
  min-height: 460px;
}

#bn-itinerary-datepicker-dialog .bn-dialog-text {
  padding: 0 0 15px 0;
}

#bn-itinerary-datepicker-dialog .bn-date-picker-months-scroll {
  min-height: 310px;
}

/* --------- itinerary roomtype edit dialog -------- */
#bn-itinerary-roomtype-dialog .bn-dialog {
  max-width: 800px;
}

#bn-itinerary-roomtype-dialog .bn-dialog svg {
  margin-right: 4px;
}

#bn-itinerary-roomtype-dialog .bn-dialog-content {
  color: inherit;
}

#bn-itinerary-roomtype-dialog h1, h3 {
  color: #383838;
}

#bn-itinerary-roomtype-dialog h1 {
  color: #383838;
}

#bn-itinerary-roomtype-dialog .bn-dialog-text {
  color: #383838;
  padding-top: 15px;
  padding-bottom: 25px;
}

#bn-itinerary-roomtype-dialog .bn-dialog-content p {
  text-align: left;
}

.bn-keep-room-button {
  font-size: 13px;
}

/* --------- cancel reservation dialog -------- */
#bn-cancel-reservation-dialog .bn-dialog {
  max-width: 800px;
  min-width: 542px;
  min-height: 375px;
}

#bn-cancel-reservation-dialog .bn-dialog-busy-container {
  min-height: 165px;
}

/* --------- guest details --------- */
.bn-guest-details {
  display: flex;
  flex-direction: column;
  width: 100%;
  font-family: inherit;
  padding: 0px 25px;
}

.bn-guest-details-container {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.bn-guest-details-container .bn-error-message {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;

  color: #d63301;
  border: 1px solid #d63301;
  border-radius: 7px 7px 0 0;
}

.bn-guest-details label {
  font-size: 15px;
  color: #383838;
  font-family: inherit;
}

.bn-guest-input-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  flex: 1 1;
  gap: 20px;
  border: 1px solid #C0C0C0;
  border-radius: 0px;
}

.bn-guest-input-container h1, .bn-guest-input-container h2 {
  padding: 25px 25px 0 25px;
}

.bn-inputs-group, .bn-payment-container {
  padding: 55px 25px;
}

.bn-room-profile-tab-container {
  background-color: white;
  padding-bottom: 25px;
}

.bn-guest-itinery-tabs {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;

  cursor: pointer;
  background: #f8f8f8;
  color: #383838;
}

.bn-guest-itinery-tabs .bn-tab {
  width: 100%;
  padding: 20px 30px;
  text-align: center;

  background: #e5e5e5;
  cursor: pointer;
  font-weight: bold;
  font-size: 18px;
  color: #7f7f7f;
  transition: background 0.1s, color 0.1s;
}

.bn-guest-itinery-tabs .bn-tab:hover {
  background: #d8d8d8;
}

.bn-guest-itinery-tabs .bn-tab.bn-selected {
  background: #fff;
}

.bn-guest-room-profile {
  padding-top: 18px;
}

.bn-inputs-group {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  gap: 20px;
}

.bn-inputs-left {
  flex: 1 1;
}

.bn-inputs-right {
  flex: 1 1;
}

.bn-group {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 25px;
}

.bn-group label {
  font-size: 16px;
  margin-bottom: 5px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  color: #383838;
}

.bn-group div.bn-error {
  color: #d63301;
  font-weight: bold;
}

.bn-group.bn-input-item input {
  height: 35px;
  font-size: 16px !important;
  border: 0;
  color: #263238;
  border-bottom: 1px solid #d9d9d9;
  font-family: inherit;
}

.bn-group input::-webkit-input-placeholder {
  font-size: 14px;
  line-height: 20px;
  vertical-align: middle;
  color: #d9d9d9;
  text-align: left;
}

.bn-group input:-moz-placeholder {
  font-size: 14px;
  line-height: 20px;
  vertical-align: middle;
  color: #d9d9d9;
  text-align: left;
}

.bn-group input::-moz-placeholder {
  font-size: 14px;
  line-height: 20px;
  vertical-align: middle;
  color: #d9d9d9;
  text-align: left;
}

.bn-group input:-ms-input-placeholder {
  font-size: 14px;
  line-height: 20px;
  vertical-align: middle;
  color: #d9d9d9;
  text-align: left;
}

.bn-group input:focus::-webkit-input-placeholder {
  color: transparent;
}

.bn-group input:focus:-moz-placeholder {
  color: transparent;
}

.bn-group input:focus::-moz-placeholder {
  color: transparent;
}

.bn-group input:focus:-ms-input-placeholder {
  color: transparent;
}

.bn-input-item input:focus {
  outline: none;
  border-bottom-color: #000;
}

.bn-input-item.error input:focus {
  outline: none;
  border-bottom-color: #d63301;
}

.bn-input-item.bn-error {
  /*outline: none;*/
  border-bottom: 1px solid #d63301;
}

.bn-group.bn-select-item {
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
}

.bn-select-no-title label {
  height: 12px;
}

.bn-dialog-content .bn-loyalty-validate-container {
  max-width: 560px ;
}

.bn-loyalty-validate-text {
  padding: 0 20px;
}

.bn-loyalty-validate-text a {
  cursor: pointer;
  font-weight: bold;
}

.bn-loyalty-validate-text a:hover {
  border-bottom: 2px solid #000;
}

.bn-loyalty-validated-container {
  display: flex;
  flex-direction: row;
  padding: 0 25px;
  gap: 20px;
}

.bn-loyalty-validate-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
}

.bn-loyalty-validate-container .bn-inputs-group {
  flex: 1 1;
  padding: 0;
}

.bn-loyalty-validate-container .bn-error-message {
  font-size: 14px;
  border-radius: 7px;
  padding: 10px;
  margin-bottom: 20px;
  line-height: 24px;

  color: #d63301;
  border: 1px solid #d63301;
}

.bn-loyalty-validate-inputs-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.bn-input-with-button-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 4px;
}

.bn-input-with-button-container .bn-button-container {
  padding-right: 0 !important;
}

.bn-input-with-button-container .bn-booknow-button svg {
  margin: 0;
}

.bn-input-with-button-container input[type="text"] {
  flex: 1 1;
}

.bn-input-with-button-container button {
  height: 38px;
  width: 40px;
  min-width: 34px;
  padding: 0px;
}

.bn-terms a {
  cursor: pointer;
  font-weight: bold;
  border-bottom: 2px solid #000;
}

/* Hide the browser's default checkbox */
#booknow-container input[type=checkbox] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.bn-checkbox-item {
  display: flex;
  align-items: baseline;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  height: auto;
  -webkit-user-select: none;
  user-select: none;
  padding-top: 4px;
  line-height: 1.8rem !important;
}

.bn-checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
}

.bn-checkbox-item:hover input ~ .bn-checkmark {
  background-color: #d9d9d9;
}

.bn-checkbox-item input:checked ~ .bn-checkmark {
  background-color: #383838;
}

.bn-checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.bn-checkbox-item input:checked ~ .bn-checkmark:after {
  display: block;
}

.bn-checkbox-item.disabled input:checked ~ .bn-checkmark:after {
  display: none;
}

.bn-checkbox-item .bn-checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

.bn-checkbox-item label {
  color: #000;
  line-height: 1.2rem;
}

.bn-checkbox-item .bn-error {
  padding: 4px;
  border: 1px solid #d63301;
}

.bn-checkbox-item.disabled {
  opacity: 50%;
}

.bn-checkbox-item:hover input ~ .bn-checkmark {
  background-color: #d9d9d9;
}

.bn-guest-additional-names-container {
  padding: 0px 25px;
}

.bn-guest-additional-names {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.bn-additional-name-group {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
}

.bn-additional-name-input {
  min-width: 200px;
}

.bn-additional-name-input div.bn-group {
  margin: 0;
}

.bn-additional-name-action {
  display: flex;
  align-items: center;
}

.bn-additional-name-action button {
  width: 35px;
  min-width: 35px;
  padding: 0px;
}

.bn-additional-name-action button svg.svg-inline--fa {
  margin: 0;
}

.bn-payment-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  line-height: 1.8rem;
}

.bn-payment-img {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.bn-comments-container {
  display: flex;
  flex-direction: row;
  padding: 0 25px;
}

.bn-comments-container textarea {
  border: 1px solid #d9d9d9;
  border-radius: 7px;
  width: 100%;
  padding: 15px;
  resize: none;
  font-family: inherit;
  font-size: 14px;
  line-height: 1.2rem;
}

.bn-comments-container textarea:focus {
  outline: 1px solid #383838;
}

.bn-payment-container img {
  width: 160px;
}

.bn-guest-input-container div.bn-button-container {
  justify-content: end;
  padding: 0 25px 25px 25px;
}

.bn-guest-input-container div.bn-button-container button {
  width: fit-content;
  padding: 10px 25px;
}

/* -----confirmation page------*/

.bn-confirmation-container {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0px 5px 25px 0px;
  border: 1px solid #C0C0C0;
  border-radius: 0px;
  width: calc(1200px - 50px);
}

.bn-confirmation-container h1, .bn-confirmation-container h2 {
  padding: 25px 25px 0 25px;
}

.bn-reservation-detail-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 15px;
  padding: 0 25px;

  justify-content: space-between;
}

.bn-reservation-detail {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.bn-reservation-detail-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.bn-reservation-detail h4 {
  flex-grow: 1;
  width: 260px;
  text-align: left;
}

.bn-reservation-detail p {
  flex-grow: 1;
  text-align: left;
  min-width: 190px;
}

.bn-reservation-actions {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  gap: 12px;
  min-width: auto;
}

.bn-reservation-actions button {
  width: 350px;
  max-width: 500px;
}

.bn-confirmation-hotel-container {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  padding: 0 25px;
}

.bn-confirmation-hotel-detail {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 25px;
  width: 100%;
}

.bn-confirmation-hotel-image {
  width: 340px;
  height: 275px;
}

.bn-confirmation-hotel-image .bn-image {
  width: 100%;
  height: 100%;

  background-position: center;
  background-size: cover;
  border-radius: 7px;
}

.bn-confirmation-hotel-description {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.bn-confirmation-hotel-description p:nth-child(6) {
  padding-top: 20px;
}

.bn-confirmation-hotel-map-container {
  flex: 1 1;
  min-width: 230px;
  width: 230px;
  height: 275px;
  border-radius: 7px;
  overflow: hidden;
}

.bn-confirmation-guest-details {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0 25px;
  align-items: flex-start;
  justify-content: space-between;
}

.bn-confirmation-guest-detail-column {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 8px;
  align-items: flex-start;
}

.bn-guest-detail-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  min-width: auto;
  width: 100%;

  text-wrap: nowrap;
  text-wrap-mode: nowrap
}

.bn-guest-detail-item h4 {
  min-width: 105px;
  text-align: left;
}

.bn-confirmation-payment-details {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.bn-confirmation-payment-details p {
  padding: 0 25px;
  line-height: 1.3rem;
}

.bn-confirmation-comments p {
  padding: 0 25px;
  line-height: 1.3rem;
}

.bn-confirmation-ts-and-cs {
  padding: 0 25px;
}

.bn-payment-error-container {
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 20px 25px 25px;
  margin-bottom: 25px;

  color: #000;
  border: 1px solid #d63301;
  border-radius: 7px 7px;
}

.bn-payment-error-container h3 {
  color: #d63301;
}

.bn-pay-now-container {
  display: flex;
  flex-direction: column;
  padding: 25px;
  width: 100%;
  max-width: 500px;

  border: 1px solid #C0C0C0;
  border-radius: 7px 7px;
}

.bn-pay-now-description-container {
  display: flex;
  flex-direction: column;
  gap: 4px;

  margin-bottom: 20px;
}

.bn-pay-now-description-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.bn-pay-now-description {
  color: #383838;
}

/*--------- Attachments ----------*/
.bn-attachments-container {
  display: flex;
  flex-direction: column;
  padding: 0 25px;
  gap: 25px;
}

.bn-attachment-items {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.bn-file-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  border-top: 1px solid #383838;
  border-left: 1px solid #383838;
  border-bottom: 1px solid #383838;
  border-radius: 7px;
}

.bn-file-name {
  flex: 1 1;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;

  padding: 15px 10px;
  text-wrap: none;
  overflow: hidden;
}

.bn-file-name span {
  white-space: nowrap;
}

.bn-file-remove {
  cursor: pointer;
}

.bn-file-remove:hover {
  color: #000;
}

.bn-file-download a {
  text-decoration: none;
  text-transform: uppercase;
  color: #fff;
  background-color: #383838;

  padding: 15px 15px;
  border-radius: 0 7px 7px 0;
}

.bn-file-download a:hover {
  background-color: #000;
}

.bn-file-upload {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  gap: 10px;
}

.bn-file-upload .bn-error {
  color: #d63301;
}

/*--------- Agent Signup ---------*/
.bn-agent-signup-container {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  gap: 20px;

  padding-bottom: 25px;
  border: 1px solid #C0C0C0;
  border-radius: 7px;
}

.bn-agent-signup-container h1, .bn-agent-signup-container h2 {
  padding: 25px 25px 0 25px;
}

.bn-agent-signup-container p {
  padding: 25px;
  font-weight: bold;
}

.bn-agent-signup-container span {
  font-weight: normal;
}

.bn-agent-signup-errors {
  visibility: hidden;
  display: flex;
  flex-direction: row;
  gap: 5px;

  padding: 15px;
  margin: 0 25px;
  border-radius: 7px;

  color: #D63301;
  background-color: #FFBABA;

  transition: visibility 0.3s linear;
}

.bn-agent-signup-errors.bn-hidden {
  visibility: hidden;
}

.bn-agent-signup-errors.bn-show {
  visibility: visible;
}

.bn-agent-pswd-change-success {
  display: flex;
  flex-direction: row;
  align-items: center;

  margin: 0 25px;
}

.bn-agent-pswd-change-success p {
  padding-left: 6px;
}

.bn-agent-pswd-change-success span {
  cursor: pointer;
}

.bn-agent-pswd-change-success span:hover {
  padding-bottom: 0;
  border-bottom: 2px solid #7f7f7f;
}

.bn-agent-pswd-change-success svg {
  font-size: 36px;

  color: #5CCC5C;
}

.bn-agent-signup-container .bn-password-restrictions {
  padding: 0 25px;
}

/*--------- login dialog ---------*/
.bn-login-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  min-width: 300px;
}

.bn-login-container .bn-inputs-group {
  padding: 0;
}

.bn-agent-login-signup-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 25px 0;

  font-size: small;
}

.bn-agent-login-signup-container span {
  height: 18px;
  cursor: pointer;
}

.bn-agent-login-signup-container span:hover {
  border-bottom: 2px solid #7f7f7f;
}

.bn-login-container .bn-error-message {
  visibility: hidden;
  display: flex;
  flex-direction: row;
  gap: 5px;

  padding: 15px;
  border-radius: 7px;

  color: #D63301;
  background-color: #FFBABA;

  transition: visibility 0.3s linear;
}

.bn-login-container .bn-error-message.bn-hidden {
  visibility: hidden;
}

.bn-login-container .bn-error-message.bn-show {
  visibility: visible;
}

.bn-login-container .bn-success-message {
  display: flex;
  flex-direction: row;
  gap: 5px;

  padding: 15px;
  border-radius: 7px;
  border: 1px solid #5CCC5C;

  color: #5CCC5C;

  transition: visibility 0.3s linear;
}

/* ------google maps marker -----*/

/* only supports color, backgorunf-color etc. Dimension changes will break marker */
.bn-googlemaps-hotel-marker {
  color: #0d7fb8;
  cursor: pointer;

  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.40) 0 6px 16px;
}

.bn-googlemaps-hotel-marker-hover {

}

.bn-googlemaps-hotel-marker svg.svg-inline--fa {
  margin-right: 0;
}

/* --------- table -----------*/
table.bn-responsive-table {
  /*border: 1px solid #ccc;*/
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: auto;
}

/*table caption {*/
/*  font-size: 1.5em;*/
/*  margin: .5em 0 .75em;*/
/*}*/

table.bn-responsive-table th {
  background-color: #f8f8f8;
  border: 1px solid #ddd;
  padding: .35em;
}

table.bn-responsive-table th,
table.bn-responsive-table td {
  padding: 10px 5px 10px 25px;
  text-align: left;
}

table.bn-responsive-table th, .bn-table-heading {
  /*font-size: .85em;*/
  /*letter-spacing: .1em;*/
  /*text-transform: uppercase;*/
  font-weight: bold;
}

.bn-responsive-table-clickable-row {
  cursor: pointer;
}

tr.bn-responsive-table-row-hover:hover {
  background-color: rgba(56, 56, 56, 0.09999999999999998);
}

tr.bn-responsive-table-row-selected {
  background-color: rgba(56, 56, 56, 0.19999999999999996) !important;
}

@media screen and (max-width: 700px) {
  table.bn-responsive-table {
    border: 0;
  }

  table.bn-responsive-table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  table.bn-responsive-table tr {
    display: block;
    margin-bottom: 0px;
    background-color: white;
    padding: 10px 0px;
    font-size: 15px;
    border-top: 1px solid #d8d8d8;
  }

  table.bn-responsive-table td {
    display: block;
    font-size: .8em;
    text-align: right;
  }

  table.bn-responsive-table td::before {
    /*
    * aria-label has no advantage, it won't be read inside a table
    content: attr(aria-label);
    */
    content: attr(data-label);
    float: left;
    font-weight: bold;
    /*text-transform: uppercase;*/
  }

  table.bn-responsive-table td:last-child {
    /*border-bottom: 0;*/
  }

  table.bn-responsive-table td.table-colspan, table.bn-responsive-table td.bn-table-heading {
    display: none;
  }
}

/* --------- tooltip --------- */

.tooltip {
  width: max-content;
  font-weight: 500;
  font-size: 90%;
  padding: 8px 10px;
  border-radius: 4px;
  z-index: 2;

  color: white;
  background-color: #444;
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.2),
  0 3px 4px 0 rgba(0, 0, 0, 0.14),
  0 3px 3px -2px rgba(0, 0, 0, 0.12);
}

/* --------- dialog --------- */

.bn-dialog-overlay {
  background: rgba(0, 0, 0, 0.1) !important;
  -webkit-backdrop-filter: blur(1px);
  backdrop-filter: blur(1px);
  display: grid;
  place-items: center;
  z-index: 100;
}

.bn-dialog {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 15px;
  padding: 20px;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2), 0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12);
  max-width: 520px;
}

.bn-dialog-content p {
  line-height: 1.4rem;
  text-align: center;
}

.bn-dialog-text {
  padding-top: 25px;
}

.bn-dialog-text p {
  text-align: left !important;
  padding: 4px 0;
}

.bn-dialog-content  {
  color: #383838;
}

.bn-dialog-button-container {
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: center;
}

.bn-dialog-idle-message h3 {
  margin-bottom: 10px;
  font-size: 18px;
}

.bn-dialog .bn-error-message {
  text-align: center;

  padding: 15px;
  margin: 15px;
  border-radius: 7px;

  color: #D63301;
  background-color: #FFBABA;
}

.bn-dialog table.bn-responsive-table {
  text-align: left;

  color: #7f7f7f;
}

.bn-dialog table.bn-responsive-table th {
  text-wrap: nowrap;
  text-wrap-mode: nowrap;
}

.bn-dialog table.bn-responsive-table th, .bn-dialog table.bn-responsive-table td {
  padding: 10px;
}

.bn-dialog-busy-container {
  display: flex;
  justify-content: center;

  min-width: 400px;
  min-height: 300px;
}

#bn-guest-lookup .bn-dialog {
  max-width: 80%;
}

.bn-confirmation-ts-and-cs p {
  line-height: 1.3rem;
}

/*-------- Floating Menu -----------*/
.bn-floating-menu {
  display: flex;
  flex-direction: column;
  gap: 15px;
  z-index: 2;

  padding: 20px;
  border-radius: 4px;
  border: none;
  width: max-content;

  background-color: #fff;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.bn-floating-menu:focus-visible {
  outline: none;
}

.bn-menu-item {
  cursor: pointer;
  color: #383838;
}

.bn-menu-item:hover {
  color: #7f7f7f;
}

@media screen and (max-width: 1000px) {
  #root {
    width: auto;
  }
}

@keyframes load3 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


/* --------- accordion --------- */

.bn-accordion {
  cursor: pointer;
  padding: 18px 25px;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  font-size: 18px;
  border-radius: 0;
  background-color: #383838;
  color: white;
  font-weight: 700;
  font-family: inherit;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.bn-accordion-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out;
}

.bn-accordion-active, .bn-accordion:hover {
  background-color: #000;
  opacity: 1;
  border: none;
  border-radius: 0px;
}

.bn-accordion:after {
  content: '\002B'; /* "plus" sign (+) */
  font-size: 24px;
  color: white;
  float: right;
  margin-left: 5px;
  font-family: sans-serif;
}

.bn-accordion-active:after {
  content: "\2212"; /* "minus" sign (-) */
}

.fade-in {
  transition: opacity .2s ease-in;
}

.fade-out {
  opacity: 0;
  /*transition: opacity 1s ease;*/
}


/* --------- scrollable tabs --------- */

.bn-scrollable-tabs-container {
  display: flex;
  flex-direction: row;
  cursor: pointer;

  max-width: 100%;
  overflow: hidden;
  position: relative;
}

.bn-scrollable-tabs-container svg {
  width: 24px;
  height: 24px;
  padding: 8px;
  cursor: pointer;
  color: #000;
  border-radius: 50%;
  pointer-events: auto;
}

.bn-scrollable-tabs-list {
  display: flex;
  flex-direction: row;
  width: 100%;
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  scroll-behavior: smooth;
}

.bn-scrollable-tabs-list .bn-tab {
  width: 100%;
  min-width: 100px;
  text-align: center;
  font-weight: bold;
  opacity: 0.5;

  padding: 15px 0;
  border-bottom: 2px solid #d8d8d8;
  transition: all 0.1s ease-in-out
}

.bn-scrollable-tabs-list .bn-tab.bn-selected {
  opacity: 1;
  border-bottom: 2px solid #000;
}

.bn-scrollable-tabs-list .bn-tab:hover {
  opacity: 1;
  border-bottom: 2px solid #000;
}

.bn-scrollable-tabs-container div::-webkit-scrollbar {
  display: none;
}

.bn-scrollable-tabs-container div.bn-selected {
  background: #fff;
  color: #383838;
}

.bn-scrollable-tabs-container .bn-right-arrow,
.bn-scrollable-tabs-container .bn-left-arrow {
  position: absolute;
  height: 100%;
  width: 100px;
  top: 0;
  display: none;
  align-items: center;
  padding: 0 10px;
  pointer-events: none;
}

.bn-scrollable-tabs-container .bn-right-arrow.active,
.bn-scrollable-tabs-container .bn-left-arrow.active {
  display: flex;
  z-index: 1;
}

.bn-scrollable-tabs-container .bn-right-arrow {
  right: 0;
  background: linear-gradient(to left, #f5f5f5 50%, transparent);
  justify-content: flex-end;
}

.bn-scrollable-tabs-container .bn-left-arrow {
  background: linear-gradient(to right, #f5f5f5 50%, transparent);
}

.bn-scrollable-tabs-container svg:hover {
  background: #d8d8d8;
}

/*-------search widget-------*/

/*.bn-search-widget-overlay {*/
/*  display: flex;*/
/*  flex-direction: column;*/

/*  flex: 1;*/
/*  gap: 14px;*/
/*  height: 100vh;*/
/*}*/

/*.bn-search-widget-overlay .overlay {*/
/*  flex: 1;*/

/*  background-color: #707070;*/
/*  opacity: 30%;*/
/*}*/

/*.bn-search-widget-overlay.overlayOff {*/
/*  height: 0;*/
/*}*/

.bn-search-widget {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  max-width: calc(1200px - 50px);
  margin: 10px auto 0 auto;
  font-size: 16px;
  background-color: white;
  border: 1px solid #C0C0C0;
  border-radius: 0px;
}

.bn-search-widget.selecting-stay {
  background-color: #D4D4D4;
  border: 1px solid white;
}

.bn-overlayOn {
  background-color: #707070;
  opacity: 30%;
}

.bn-search-widget-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px;
  flex: 1 1;
  max-height: 120px;
}

.bn-search-widget-item .label {
  color: #383838;
  font-weight: bold;
}

.bn-search-widget-item:hover {
  background-color: white;
  border-radius: 0px;
}

.bn-search-widget-item.bn-selected {
  background-color: #fff;
  border-radius: 0px;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.2);
}

.bn-search-widget .bn-divider {
  width: 1px;
  margin: 10px 0;
  border-right: 1px solid #C0C0C0;
}

.bn-searchwidget-portal-container {
  z-index: 2;
  padding: 30px;
}

.bn-searchwidget-portal-container .bn-button-container {
  padding-bottom: 0;
  display: none;
}


/* Hotel Suggestion */

.bn-hotel-suggestion input {
  max-width: 280px;
  padding: 0;
}

.bn-hotel-suggestion-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-left: 20px;
  z-index: 100;

  background-color: #fff;
  border-radius: 7px;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.04),
  0 4px 5px rgba(0, 0, 0, 0.06),
  0 2px 4px -1px rgba(0, 0, 0, 0.09);
}

.bn-hotel-suggestion-container:focus-visible {
  outline: none;
}

.bn-hotel-suggestion-container h3 {
  font-size: 140%;
  color: #383838;
}

.bn-suggestions {
  display: flex;
  flex-direction: column;
  width: auto;
  gap: 15px;
  padding: 0 10px 20px 10px;
  overflow: auto;
  min-width: 280px;
}

.bn-suggestion {
  /*width: 300px;*/
  cursor: pointer;
  padding-bottom: 8px;
}

.bn-suggestion.bn-selected {
  font-weight: bold;
  padding-bottom: 6px;
  border-bottom: 2px solid #707070;
}

.bn-hidden {
  display: none;
}

.bn-suggestion:hover {
  font-weight: bold;
}

.bn-suggestions-back {
  width: 24px;
  padding-right: 4px;
  color: #383838;
  font-size: 24px;
  padding-left: 8px;
}

.bn-suggestions-back svg {
  padding-top: 6px;
  cursor: pointer;
  padding-bottom: 4px;
}

.bn-suggestions-back svg:hover {
  color: #000;
}

/* Date Picker */

.bn-date-picker {
  flex-direction: row;
  gap: 10px;
}

.bn-date-picker-date {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.bn-date-picker-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 100;

  background-color: #fff;
  border-radius: 7px;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.04),
  0 4px 5px rgba(0, 0, 0, 0.06),
  0 2px 4px -1px rgba(0, 0, 0, 0.09);
}

.bn-date-picker-footer {
  display: flex;
  flex-direction: column;
  align-items: center;

  color: #383838;
  padding: 20px 0;
}

.bn-date-picker-footer h1 {
  font-size: 22px;
}

.bn-date-picker-footer p {

}

.rdp-caption_label {
  color: #383838 !important;
}

/* Guest Picker */

.bn-pax-picker-container {
  min-width: 300px;
  background-color: #fff;
  z-index: 100;
  border-radius: 7px;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.04),
  0 4px 5px rgba(0, 0, 0, 0.06),
  0 2px 4px -1px rgba(0, 0, 0, 0.09);
  max-width: 420px !important;
}

.bn-pax-picker-container h3 {
  color: #383838;
}

.bn-pax-picker-container .bn-button-container {
  padding-top: 20px;
}

.bn-pax-inputs {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  overflow: auto;
}

.bn-pax-inputs > .bn-pax-input {
  border-bottom: none;
}

.bn-pax-input {
  display: flex;
  flex-direction: row;
}

.bn-pax-input h3 {
  padding: 20px 0;
  line-height: 30px;
  font-size: 16px;
  width: 75px;
}

.bn-pax-input .bn-kids-input h3 {
  width: 35px;
}

.bn-pax-input-button-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.bn-pax-input-value {
  min-width: 16px;
  text-align: center;
}

.bn-pax-input button {
  background-color: #fff;
  border: none;
  color: #383838;
  padding: 12px 12px;
  font-size: 24px;
  cursor: pointer;
  opacity: 1;
  transition: 0.3s;
  outline: none;
}

.bn-kids-inputs {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 740px;
  overflow: hidden;
}

.bn-pax-kids-input-container {
  display: flex;
  height: 70px;
}

.bn-kids-input h3 {
  margin-left: 25px;
}

.bn-rate-not-available div {
  color: #d63301;
  font-weight: 600;
}

/*.pax-input .age-multi-select {*/
/*  padding: 12px 0;*/
/*  width: 310px;*/
/*}*/

/*.age-select__multi-value {*/
/*  color: #000;*/
/*}*/

.bn-pax-input button:hover {
  color: #000;
}

/* Promo */
.bn-promo-input {
  max-width: 200px;
}

.bn-search-widget input[type=text] {
  background: none;
  border: none;
  color: #707070;
  height: 22px;
  outline: none;
  font-family: inherit;
  font-size: 16px;
  position: relative;
  top: 3px;
}

.bn-search-widget input[type=text]::placeholder {
  color: #707070;
  opacity: 1; /* Firefox */
}

.bn-search-widget input[type=text]::-ms-input-placeholder { /* Edge 12 -18 */
  color: #707070;
}

.bn-terms-expand-container p {
  font-size: 14px;
  line-height: 1.2rem;
  color: #000000;
}

/*.bn-pax-inputs {*/
/*  display: flex;*/
/*  flex-direction: row;*/
/*}*/

.bn-adults-input select, .bn-kids-input select {
  height: 47px;
}

.adults-input select {
  background: none;
  padding: 12px 12px;
  font-weight: bold;

  border: 1px solid #C0C0C0;
  border-radius: 7px 0 0 7px;
}

.bn-kids-input select {
  background: none;
  padding: 12px 12px;
  font-weight: bold;

  border-top: 1px solid #C0C0C0;
  border-right: 1px solid #C0C0C0;
  border-bottom: 1px solid #C0C0C0;
  border-left: none;
  border-radius: 0 7px 7px 0;
}

.bn-search-widget .bn-button-container {
  flex: 1 1;
}

/*-------search widget mobile-------*/
.bn-search-widget-where {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;

  border: 1px solid #C0C0C0;
  border-radius: 7px;
  padding: 15px;
  margin: 0 25px;
  cursor: pointer;
}

.bn-search-widget-mobile input[type=text] {
  background: none;
  height: 21px;
  color: #707070;
  border: none;
  outline: none;
  font-family: inherit;
  font-size: 16px;
  position: relative;
  top: 2px;
}

.bn-search-widget-where strong, .bn-search-widget-where svg {
  color: #000;
}

.bn-search-widget-mobile .bn-search-widget-items {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 0;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  background-color: #FFFFFF;
}

.bn-search-widget-mobile .bn-search-widget-items.bn-show {
  height: 100vh;
  z-index: 2;
}

.bn-search-widget-header {
  display: flex;
  padding: 25px;
  justify-content: right;

  width: 100vw;
}

.bn-search-widget-header svg {
  color: #000;
  font-size: 24px;
}

.bn-search-widget-mobile .bn-search-widget-item {
  border: 1px solid #C0C0C0;
  border-radius: 7px;
  width: calc(100vw - 50px);
  max-height: 72px;
}

#bn-suggestion-portal-mobile .bn-hotel-suggestion-container {
  flex-direction: column;
  max-height: 400px;
  overflow: auto;
}


/*----- agent profile page --------*/
.bn-agent-profile-container {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-bottom: 4px;
  border: 1px solid #C0C0C0;
  border-radius: 0px;
  margin: 25px;
  width: calc(100% - 50px);
}

.bn-agent-profile-container h1 {
  padding: 25px 25px 0 25px;
}

.bn-bookings-list-spinner-container {
  margin: 20px;
}

.bn-agent-profile-contact-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
  padding: 0 25px;
}


/*----- udpate profile page --------*/

.bn-update-guest-profile-container {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-bottom: 25px;
  border: 1px solid #C0C0C0;
  border-radius: 0px;
  max-height: 150px;
  transition: max-height 0.4s ease-in-out;
  overflow: hidden;
  margin: 25px;
  width: calc(100% - 50px);
}

.bn-show-profile-edit {
  overflow: hidden;
  max-height: 1020px;
  transition: max-height 0.4s ease-in-out;
}

.bn-update-guest-profile-container h1, .bn-update-guest-profile-container h2 {
  padding: 25px 25px 0 25px;
}

.bn-update-guest-action-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
  padding: 0 25px;
  margin-bottom: 25px;
}

.bn-update-guest-profile-container .bn-error-message {
  width: auto;
  margin: 0 20px;
}

/*------- guest lookup dialog -----*/
#bn-guest-lookup {

}

#bn-guest-lookup h2 {
  margin-bottom: 20px;
}

#bn-guest-lookup svg {
  margin-right: 4px;
}

.bn-guest-lookup-filters-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;

  gap: 20px
}
.bn-guest-lookup-filter-items {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex: 1 1;

  gap: 20px;
}

.bn-guest-lookup-filter-items .bn-group {
  flex: 1 1;
}

.bn-guest-lookup-filters-container button {
  min-width: 100px;
  margin-bottom: 20px;
}

.bn-guest-lookup-table-container {
  min-height: 400px;
  max-height: 400px;
  max-width: 1200px;
  overflow: auto;

  margin-bottom: 10px;
}


#bn-guest-lookup td {
  overflow: hidden;
}

.bn-guest-lookup-dialog-button-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 4px;
}

/*-------react day picker ---------*/

:root {
  --rdp-cell-size: 40px;
  --rdp-accent-color: #383838;
  --rdp-background-color: #e7edff;
  /* Switch to dark colors for dark themes */
  --rdp-accent-color-dark: #3003e1;
  --rdp-background-color-dark: #180270;
  /* Outline border for focused elements */
  --rdp-outline: 2px solid var(--rdp-accent-color);
  /* Outline border for focused and selected elements */
  --rdp-outline-selected: 2px solid rgba(0, 0, 0, 0.75);
}

.rdp {

}

/* Hide elements for devices that are not screen readers */
.rdp-vhidden {
  box-sizing: border-box;
  margin: 0;
  background: transparent;
  -webkit-appearance: none;
  appearance: none;
  position: absolute !important;
  top: 0;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  overflow: hidden !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  border: 0 !important;
}

/* Buttons */
.rdp-button_reset {
  appearance: none;
  position: relative;
  margin: 0;
  padding: 0;
  cursor: default;
  color: inherit;
  outline: none;
  background: none;
  font: inherit;

  -moz-appearance: none;
  -webkit-appearance: none;
}

.rdp-button {
  border: 2px solid transparent;
}

.rdp-button[disabled]:not(.rdp-day_selected) {
  opacity: 0.25;
  pointer-events: none;
  text-decoration: line-through;
}

.rdp-button:not([aria-disabled='true']) {
  cursor: pointer;
  color: black;
}

.rdp-button:focus,
.rdp-button:active {
  color: inherit;
  border: 2px solid #383838;
  border: var(--rdp-outline);
  background-color: #e7edff;
  background-color: var(--rdp-background-color);
}

.rdp-button:hover:not([aria-disabled='true']) {
  background-color: #e7edff;
  background-color: var(--rdp-background-color);
}

.rdp-months {
  display: flex;
}

.rdp-month {
  margin: 0 1em;
}

.rdp-month:first-child {
  margin-left: 0;
}

.rdp-month:last-child {
  margin-right: 0;
}

.rdp-table {
  margin: 0;
  max-width: calc(40px * 7);
  max-width: calc(var(--rdp-cell-size) * 7);
  border-collapse: collapse;
}

.rdp-with_weeknumber .rdp-table {
  max-width: calc(40px * 8);
  max-width: calc(var(--rdp-cell-size) * 8);
  border-collapse: collapse;
}

.rdp-caption {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  text-align: left;
}

.rdp-multiple_months .rdp-caption {
  position: relative;
  display: block;
  text-align: center;
}

.rdp-caption_dropdowns {
  position: relative;
  display: inline-flex;
}

.rdp-caption_label {
  position: relative;
  z-index: 1;
  display: inline-flex;
  align-items: center;
  margin: 0;
  padding: 0 0.25em;
  white-space: nowrap;
  color: currentColor;
  border: 0;
  border: 2px solid transparent;
  font-family: inherit;
  font-size: 140%;
  font-weight: bold;
}

.rdp-nav {
  white-space: nowrap;
}

.rdp-multiple_months .rdp-caption_start .rdp-nav {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.rdp-multiple_months .rdp-caption_end .rdp-nav {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.rdp-nav_button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  width: var(--rdp-cell-size);
  height: 40px;
  height: var(--rdp-cell-size);
  padding: 0.25em;
  border-radius: 100%;
}

/* ---------- */
/* Dropdowns  */
/* ---------- */

.rdp-dropdown_year,
.rdp-dropdown_month {
  position: relative;
  display: inline-flex;
  align-items: center;
}

.rdp-dropdown {
  -webkit-appearance: none;
  appearance: none;
  position: absolute;
  z-index: 2;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  margin: 0;
  padding: 0;
  cursor: inherit;
  opacity: 0;
  border: none;
  background-color: transparent;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

.rdp-dropdown[disabled] {
  opacity: 1;
  opacity: initial;
  color: inherit;
}

.rdp-dropdown:focus:not([disabled]) + .rdp-caption_label,
.rdp-dropdown:active:not([disabled]) + .rdp-caption_label {
  border: 2px solid #383838;
  border: var(--rdp-outline);
  border-radius: 6px;
  background-color: #e7edff;
  background-color: var(--rdp-background-color);
}

.rdp-dropdown_icon {
  margin: 0 0 0 5px;
}

.rdp-head {
  border: 0;
}

tr.rdp-head_row,
tr.rdp-row {
  height: 100%;
}

th.rdp-head_cell {
  vertical-align: middle;
  text-transform: uppercase;
  font-size: 0.75em;
  font-weight: 700;
  text-align: center;
  height: 100%;
  height: 40px;
  height: var(--rdp-cell-size);
  padding: 0;

  background-color: #FFFFFF;
}

.rdp-tbody {
  border: 0;
}

.rdp-tfoot {
  margin: 0.5em;
}

td.rdp-cell {
  width: 40px;
  width: var(--rdp-cell-size);
  height: 100%;
  height: 40px;
  height: var(--rdp-cell-size);
  padding: 0;
  text-align: center;

  background-color: #FFFFFF;
}

.rdp-weeknumber {
  font-size: 0.75em;
}

.rdp-weeknumber,
.rdp-day {
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 40px;
  width: var(--rdp-cell-size);
  max-width: 40px;
  max-width: var(--rdp-cell-size);
  height: 40px;
  height: var(--rdp-cell-size);
  margin: 0;
  border: 2px solid transparent;
  border-radius: 100%;
}

.rdp-day_today:not(.rdp-day_outside) {
  font-weight: bold;
}

.rdp-day_selected:not([aria-disabled='true']),
.rdp-day_selected:focus:not([aria-disabled='true']),
.rdp-day_selected:active:not([aria-disabled='true']),
.rdp-day_selected:hover:not([aria-disabled='true']) {
  color: white;
  background-color: #383838;
  background-color: var(--rdp-accent-color);
}

.rdp-day_selected:focus:not([aria-disabled='true']) {
  border: 2px solid rgba(0, 0, 0, 0.75);
  border: var(--rdp-outline-selected);
}

.rdp:not([dir='rtl']) .rdp-day_range_start:not(.rdp-day_range_end) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.rdp:not([dir='rtl']) .rdp-day_range_end:not(.rdp-day_range_start) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.rdp[dir='rtl'] .rdp-day_range_start:not(.rdp-day_range_end) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.rdp[dir='rtl'] .rdp-day_range_end:not(.rdp-day_range_start) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.rdp-day_range_end.rdp-day_range_start {
  border-radius: 100%;
}

.rdp-day_range_middle {
  border-radius: 0;
}

/*------- media queries -------*/

@media screen and (max-width: 1210px) and (min-width: 1142px) {
  .bn-search-widget {
    width: 100%;
  }
  .bn-search-results-container {
    flex: 1 1;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 38px;
    width: 100%;
    justify-content: space-between;
  }
  .bn-hotel {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 340px;
    height: 380px;
    cursor: pointer;
    background-color: white;
    border-radius: 8px;
  }
}

@media screen and (min-width: 1141px) {
  .bn-itinerary-mobile {
    display: none;
  }

  .bn-search-widget-mobile {
    display: none;
  }

  ::-webkit-scrollbar {
    width: 5px
  }

  ::-webkit-scrollbar-track {
    background: #edeeec
  }

  ::-webkit-scrollbar-thumb {
    background: #7f7f7f
  }
}

@media screen and (max-width: 1140px) and (min-width: 831px) {
  .bn-itinerary {
    display: none;
  }
  .bn-search-widget {
    display: none;
  }
  .bn-search-widget-where {
    margin: 10px 25px;
    border-radius: 0px;
  }
  .bn-page-container {
    position: relative;
    display: flex;
    flex-direction: column;
    max-width: 1200px;
    margin: 25px auto 25px auto;
    padding-bottom: 90px;
  }
  .bn-accordion-active, .bn-accordion:hover {
    border-radius: 0px;
  }
  #bn-guest-lookup .bn-dialog {
    max-width: 95%;
  }
  .bn-update-guest-action-container {
    padding: 0 20px;
  }
  .bn-update-guest-profile-container {
    border-radius: 0px;
    margin: 25px;
  }
  .bn-hotel {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 300px;
    cursor: pointer;
    background-color: white;
    border-radius: 8px;
    margin: 0 auto;
    width: 304px;
  }
  .bn-hotel-not-available strong {
    color: #d63301;
    font-size: 15px;
  }
  .bn-hotel-not-available {
    color: #7f7f7f;
    padding: 8px 0 0 0;
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
  }
  .booknow-container h3 {
    font-size: 16px;
    font-weight: bold;
    margin: 5px 0;
    color: #383838;
    text-align: left;
  }
  .bn-searchwidget-portal-container .bn-button-container {
    padding-bottom: 0;
    display: flex;
    justify-content: center;
  }
}

@media screen and (max-width: 830px) {
  .bn-page-container {
    padding: 0px 0px 90px 0px;
  }
  .bn-search-widget-where {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
    border: 1px solid #C0C0C0;
    border-radius: 0px;
    padding: 12px;
    margin: 0px;
    cursor: pointer;
    font-size: 15px;
    white-space: nowrap;
    overflow: hidden;
    height: 65px;
  }
  .bn-search-widget-where div {
    line-height: 1.2rem;
  }
  .bn-search-widget {
    display: none;
  }
  .bn-hotel-suggestion-container {
    flex-wrap: revert;
    gap: 20px;
    width: 90%;
  }
  .bn-hotel-suggestion-container .bn-button-container {
    display: none;
  }
  .bn-suggestions {
    gap: 10px;
    width: 100%;
  }
  .bn-room-info {
    width: 100%;
    flex: 1 1;
  }
  .bn-date-picker-months-scroll {
    overflow: auto;
  }
  .bn-date-picker-footer {
    margin-top: 10px;
  }
  .bn-date-picker-container p {
    padding: 20px 0px;
  }
  #bn-date-picker-portal-mobile .rdp-months {
    flex-direction: column;
  }
  .bn-promo-input {
    max-width: revert;
  }
  .bn-search-results {
    flex-direction: column;
    padding: 0px 25px;
    width: 100%;
  }
  .bn-guest-input-container {
    border: none;
    border-radius: 0;
  }
  .bn-guest-input-container .bn-button-container {
    justify-content: center;
    flex-direction: column;
    flex-wrap: wrap;
  }
  .bn-itinerary {
    display: none;
  }
  #bn-itinerary-datepicker-dialog .rdp-months {
    flex-direction: column;
  }
  #bn-itinerary-datepicker-dialog .bn-date-picker-months-scroll {
    max-height: 420px;
  }
  .bn-desposit-container {
    margin: revert;
  }
  .bn-hotel-banner {
    gap: 0;
    flex-direction: column-reverse;
  }
  .bn-hotel-banner .bn-hotel-slide-container {
    flex: none;
    margin-bottom: 20px;
  }
  .bn-hotel-description {
    margin-bottom: 0;
  }
  .bn-room-detail-container {
    gap: 12px;
    display: flex;
    flex-direction: column;
  }
  .bn-search-widget-mobile {
    margin: 10px 25px 0px 25px;
  }
  .bn-rate-info {
    display: flex;
    flex-direction: column !important;
    gap: 4px;
  }
  .booknow-container h2 {
    font-size: 20px;
    font-weight: bold;
    margin: 28px 0px 14px 0px;
    text-transform: none;
    color: #383838;
  }
  .bn-dialog-content.bn-dialog-idle-message p {
    font-size: 14px;
  }
  .bn-inputs-group {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    gap: 0px;
  }
  .bn-inputs-group, .bn-payment-container {
    padding: 0px;
  }
  .bn-login-name {
    position: absolute;
    top: 130px;
    color: #000;
    font-weight: 600;
  }
  .bn-searchwidget-portal-container .bn-button-container {
    padding-bottom: 0;
    display: flex;
    justify-content: center;
  }
  .bn-button-container {
    padding: 0px;
  }
  .bn-comments-container {
    display: flex;
    flex-direction: row;
    padding: 0px;
  }
  .bn-guest-input-container h1, .bn-guest-input-container h2 {
    padding: 5px 25px 0px 0px;
  }
  .bn-accordion-active, .bn-accordion:hover {
    background-color: #000;
    opacity: 1;
    border: none;
    border-radius: 8px;
  }
  .bn-guest-additional-names-container {
    padding: 0px;
  }
  .bn-comments-container {
    padding: 0px;
  }
  .bn-guest-input-container div.bn-button-container {
    padding: 25px;
  }
  .bn-update-guest-profile-container {
    border-radius: 0px;
    margin: 25px;
  }
  .bn-page-header {
    height: 155px;
    top: -40px;
  }
  .bn-page-header.lift {
    box-shadow: #d1d1d1 0px 1px 5px 0px;
    height: auto;
    min-height: 150px;
  }
  table.bn-responsive-table td {
    display: flex;
    font-size: 0.9em;
    text-align: left;
    flex-direction: row;
    justify-content: space-between;
    padding: 4px 25px;
  }
  #bn-guest-lookup .bn-dialog {
    max-width: 90%;
    height: auto;
  }
  .bn-guest-lookup-filters-container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
  }
  .bn-guest-lookup-table-container {
    min-height: 300px;
    max-height: 400px;
    max-width: 100%;
    overflow: auto;
    margin-bottom: 10px;
  }
  .bn-show-profile-edit {
    overflow: hidden;
    max-height: fit-content;
    transition: max-height 0.4s ease-in-out;
    padding: 0px 25px 25px 25px;
  }
  .bn-update-guest-profile-container.bn-show-profile-edit {
    max-height: fit-content;
  }
  .bn-update-guest-profile-container {
    border-radius: 0px;
    margin: 25px;
    min-height: 195px;
  }
  .bn-update-guest-profile-container h1, .bn-update-guest-profile-container h2 {
    padding: 15px 25px 0px 0px;
  }
  .bn-update-guest-action-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    gap: 10px;
    padding: 0 25px;
    margin-bottom: 25px;
    justify-content: center;
    text-align: center;
    font-size: 15px;
  }
  .bn-update-guest-profile-container h1 {
    padding-left: 25px;
  }
  .bn-image-gallery-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    height: 100%;
    padding: 10px;
    outline: none;
    background-color: #fff;
  }
  .bn-image-gallery-images .bn-image {
    width: 100%;
    height: 100%;
    max-width: 900px;
    max-height: 300px;
    background-position: center;
    background-size: cover;
  }
  .bn-search-results-container {
    flex: 1 1;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 38px;
    justify-content: center;
  }
  .bn-hotel {
    width: 100%;
  }
  .bn-hotel-not-available {
    color: #7f7f7f;
    padding: 8px 0 0 0;
    font-size: 14px;
  }
  .bn-rate-detail-container {
    display: flex;
    flex-direction: column;
    /*align-items: flex-start;*/
    /*justify-content: space-between;*/
    margin-top: 20px;
    width: 100%;
  }
  .bn-rate-button-container {
    display: flex;
    flex-direction: column;
    gap: 6px;
    align-items: center;
  }
  .bn-group.bn-input-item input {
    border-radius: 0px;
  }
  .bn-checkbox-item {
    display: flex;
    align-items: baseline;
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    height: auto;
    -webkit-user-select: none;
            user-select: none;
    padding-top: 2px;
  }
  .bn-checkbox-item label {
    color: #000;
    line-height: 1.4rem;
  }
  .bn-terms a {
    cursor: pointer;
    font-weight: bold;
    border-bottom: 2px solid #000;
    line-height: 1.4rem;
  }
  .bn-page-container {
    padding: 0px 0px 90px 0px;
    margin: 0 auto;
    max-width: 100vw;
    flex-direction: column;
  }
  .bn-reservation-detail-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    justify-content: center;
  }
  .bn-reservation-detail-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 35px;
    padding: 0px 25px;
    justify-content: center;
    align-items: center;
  }
  .bn-confirmation-container {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 0px 5px 25px 0px;
    border: 1px solid #C0C0C0;
    border-radius: 0px;
    width: 100%;
  }
  .bn-reservation-detail {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  .bn-reservation-detail h4 {
    flex-grow: 1;
    text-align: left;
    width: 70%;
  }
  .bn-reservation-detail p {
    flex-grow: 1;
    text-align: end;
    width: 30%;
  }
  .bn-confirmation-hotel-image {
    width: 100%;
    height: 275px;
  }
  .bn-reservation-actions {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 12px;
    min-width: 250px;
  }
  .bn-reservation-actions button {
    width: 300px;
    max-width: 350px;
  }
  .bn-confirmation-hotel-container {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    padding: 0px 25px;
  }
  .bn-confirmation-hotel-map-container {
    flex: 1 1;
    min-width: 230px;
    width: 100%;
    min-height: 275px;
    border-radius: 7px;
    overflow: hidden;
  }
  .bn-confirmation-guest-detail-column {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-bottom: 8px;
    width: 100%;
  }
  .bn-guest-detail-item p {
    text-align: end;
    width: 70%;
  }
  .bn-guest-detail-item h4 {
    min-width: 30%;
    text-align: left;
    padding-left: 0px;
  }
  .bn-reservation-detail-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 35px;
    padding: 0px 25px;
    justify-content: center;
    align-items: center;
  }
  .bn-confirmation-hotel-detail {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 25px;
    width: 100%;
  }
  .bn-confirmation-hotel-description {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
  .bn-confirmation-ts-and-cs p {
    line-height: 1.3rem;
  }
  .bn-confirmation-comments p {
    padding: 0 25px;
    line-height: 1.3rem;
  }
  .bn-hotel-detail-container {
    display: flex;
    flex-direction: column;
    padding: 0px 25px 30px 25px;
  }
  .bn-room-description p {
    line-height: 1.4em;
    font-size: 15px;
  }
  #bn-cancel-reservation-dialog .bn-dialog {
    min-width: revert;
  }
}
